import { useMessagePopup } from '@/composables/useMessagePopup';

const { openMessagePopup } = useMessagePopup();

export const useFormHelp = () => {
  /**
   * @description метод с помощью которого делается установка ошибок с бекенда в инпуты формы
   * @param errorResponse {object} - результат ошибочного ответа от сервера
   * @param setErrors {function} - метод от vee-validate с помощью которого в инпут будет ставиться ошибка
   * @param sendAnalyticError {function|null} - функция для отправки аналитики ошибок
   * @param analyticData {object|null} - если кроме данных ошибки нужны еще дополнительные, то прокинуть объект например {step: 'шаг-1'}
   */
  const checkErrors = function (
    errorResponse,
    setErrors,
    sendAnalyticError = null,
    analyticData = null,
  ) {
    if (!setErrors || !errorResponse) return;

    const errorCode = errorResponse.code;
    const errors = errorResponse?.errors || {};
    const errorsName = Object.keys(errors)?.[0];
    const errorMessage = errors?.[errorsName];

    // получение данных ошибки для аналитики
    let analyticErrorData = {
      errorsName: errorsName,
      errorMessage: errorMessage,
    };

    // получение общих данных для аналитики
    if (analyticData) {
      analyticErrorData = {
        ...analyticErrorData,
        ...analyticData,
      };
    }

    // отправка аналитики
    if (sendAnalyticError) {
      sendAnalyticError(analyticErrorData);
    }

    // частный случай - если нужно показать ошибку при определенных условиях
    if (errorCode === 42200 && errorsName === 'mindboxDeviceUuid') {
      openMessagePopup({
        text: errorMessage,
        isSuccess: false,
      });

      return;
    }

    // частный случай - если нужно показать ошибку при определенных условиях
    if (errorCode === 42200 && errorsName === 'captcha') {
      openMessagePopup({
        text: errorMessage,
        isSuccess: false,
      });

      return;
    }

    // сетим ошибку в поле формы
    if (errorResponse.errors) {
      Object.entries(errorResponse.errors).forEach(([fieldName, message]) => {
        setErrors({ [fieldName]: [message] });
      });

      return;
    }

    // если ничего не подошло, то выводим попап с описанием ошибки
    return openMessagePopup({
      text: errorResponse.message || 'Что-то пошло не так',
      isSuccess: false,
    });
  };

  return {
    checkErrors,
  };
};
